<script lang="ts">
import { defineComponent } from 'vue'
import apiClient from '@/api/api'
import Icon from '@/components/Icon.vue'
import ProductCard from '@/components/ProductCard.vue'
import SingleProductSpecifics from '@/components/SingleProductSpecifics.vue'
import { Swiper } from 'swiper'
import { Pagination, Zoom } from 'swiper/modules'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PageNotFound from '@/components/PageNotFound.vue'
import { event as gtagEvent } from 'vue-gtag'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'
import { isClient } from '@/utils/helpers'
import ClientOnly from '@duannx/vue-client-only'

export default defineComponent({
  name: 'SingleProduct',
  components: {
    ProductCard,
    Icon,
    SingleProductSpecifics,
    Breadcrumb,
    PageNotFound,
    ClientOnly
  },
  beforeRouteEnter(to, from, next) {
    if (isClient && useStore().products[to.params.id as string]) {
      next()
      return
    }
    apiClient.fetchProduct(to.params.id as string).then(resp => {
      next((vm: any) => {
        vm.setProduct(resp)
        vm.resetAmounts()
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    apiClient.fetchProduct(to.params.id as string).then(resp => {
      next((vm: any) => {
        vm.setProduct(resp)
        vm.resetAmounts()
      })
    })
  },
  setup() {
    const store = useStore()
    const { setProduct, fetchProduct, fetchRelatedProducts, addToCart } =
      useStore()
    const { relatedProducts, products } = storeToRefs(store)
    return {
      setProduct,
      fetchProduct,
      fetchRelatedProducts,
      addToCart,
      relatedProducts,
      products
    }
  },
  data() {
    return {
      amount: {} as any,
      activeVariantId: null,
      addingToCartState: 'idle',
      swiper: null as any
    }
  },
  head() {
    if (!this.product) {
      return {}
    }
    let ldJson = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      sku: this.product.sku,
      name: this.product.name,
      description: this.product.description
    } as any
    if (this.product.type === 'variable') {
      // TODO: Set for variable products
    } else if (this.product.price.price > 0) {
      ldJson.offers = {
        '@type': 'Offer',
        availability: this.product.stock.in_stock
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock',
        price: this.product.price.price,
        priceCurrency: 'SEK'
      }
    }
    if (this.product.brands.length > 0) {
      ldJson.brand = {
        '@type': 'Brand',
        name: this.product.brands[0].name
      }
      if (this.product.brands[0].thumbnail) {
        ldJson.brand.logo = this.product.brands[0].thumbnail.url
      }
    }

    let meta = {
      title: this.product.name,
      meta: [
        {
          property: 'og:title',
          content: this.product.name,
          vmid: 'og:title'
        },
        {
          property: 'og:image',
          content: this.product.featured_image.url,
          vmid: 'og:image'
        },
        {
          property: 'og:description',
          content: this.product.short_description,
          vmid: 'og:description'
        },
        {
          property: 'og:url',
          content: import.meta.env.VITE_FRONTEND_URL + this.$route.path,
          vmid: 'og:url'
        },
        { name: 'description', content: this.product.short_description }
      ],
      script: [
        {
          type: 'application/ld+json',
          textContent: JSON.stringify(ldJson)
        }
      ]
    }

    return meta
  },
  computed: {
    activeVariant() {
      if (!this.product) {
        return null
      }
      if (this.activeVariantId === null) {
        return this.product.variations && this.product.variations.length > 0
          ? this.product.variations[0]
          : this.product
      }
      return this.product.variations.find(
        (v: any) => v.id === this.activeVariantId
      )
    },
    addToCartText() {
      if (this.addingToCartState === 'loading') {
        return 'Lägger till...'
      } else if (this.addingToCartState === 'added') {
        return 'Tillagd'
      } else {
        return 'Lägg till i varukorg'
      }
    },
    listRelatedProducts() {
      return this.relatedProducts[this.$route.params.id as string]
    },
    product() {
      return this.products[this.$route.params.id as string]
    },
    totalAmount() {
      let total = 0
      for (let amount of Object.values(this.amount)) {
        total += amount as number
      }
      return total
    }
  },
  // Client-side only
  watch: {
    '$route.params.id': {
      handler() {
        if (!this.listRelatedProducts && this.listRelatedProducts !== false) {
          this.fetchRelatedProducts(this.$route.params.id as string)
        }
      },
      immediate: true
    },
    product: {
      handler() {
        this.resetAmounts()
      },
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper', {
        direction: 'horizontal',
        modules: [Pagination, Zoom],
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        zoom: true,
        grabCursor: true
      })
    }, 1000)
  },
  methods: {
    addToCartNow() {
      this.addingToCartState = 'loading'
      var items = []
      if (this.product && this.amount[this.product.id] > 0) {
        items.push({
          product_id: this.product.id,
          quantity: this.amount[this.product.id]
        })
      }

      for (let variant of this.product.variations || []) {
        if (this.amount[variant.id] > 0) {
          items.push({
            product_id: this.product.id,
            variation_id: variant.id,
            quantity: this.amount[variant.id]
          })
        }
      }
      if (items.length === 0) {
        return
      }
      gtagEvent('add_to_cart', {
        items: items.map(i => ({
          id: this.product.sku,
          name: this.product.name,
          quantity: i.quantity
        }))
      })
      this.addToCart(items).then(() => {
        this.addingToCartState = 'added'
        setTimeout(() => {
          this.addingToCartState = 'idle'
        }, 2000)
      })
      this.resetAmounts()
    },
    resetAmounts() {
      this.amount = {}
      this.activeVariantId = null

      if (!this.product) {
        return
      }
      this.amount[this.product.id] = 1
      /**
       * If this is a variable product reset the value to 0 so that
       * the base-product isn't pre-selected and can be added to the cart
       */
      if (this.product.variations !== undefined) {
        this.amount[this.product.id] = 0
        for (let [index, variant] of this.product.variations.entries()) {
          /**
           * If this is the first variant give that a value of 1 so
           * that it is pre-selected else make sure it is not selected
           */
          if (index === 0) {
            this.activeVariantId = variant.id
          }
          this.amount[variant.id] = 0
        }
      }
    },
    imgPlaceholder(e: any) {
      e.target.src = '/djur&natur.svg'
    },
    hasAttrib(product: any, attrib: string) {
      return (
        typeof product.attributes[`attribute_${attrib}`] !== 'undefined' &&
        product.attributes[`attribute_${attrib}`] !== ''
      )
    }
  },
  // Server-side only
  serverPrefetch() {
    return Promise.all([
      this.fetchProduct(this.$route.params.id as string),
      this.fetchRelatedProducts(this.$route.params.id as string)
    ])
  }
})
</script>

<template>
  <PageNotFound v-if="!product" />
  <div v-else>
    <div class="wrapper">
      <Breadcrumb />
      <div></div>
    </div>
    <div class="product">
      <div class="product-gallery">
        <client-only>
          <div
            v-if="activeVariant.gallery_images.length >= 1"
            class="swiper swiper-container"
          >
            <p class="zoom-text">
              Dubbelklicka på bilderna för att zooma
              <Icon name="searchPlus" type="fas" />
            </p>
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="swiper-zoom-container">
                  <!-- TODO: Width and height should prob. be a bit more dynamic, added 500x500 to see if we could get rid of some CLS -->
                  <img
                    :src="
                      product.featured_image.sizes !== undefined
                        ? product.featured_image.sizes.shop_single
                        : product.featured_image.url
                    "
                    :alt="`Bild på ${product.name}`"
                    width="500"
                    height="500"
                    class="swiper-lazy"
                    loading="eager"
                    @error="imgPlaceholder"
                  />
                </div>
              </div>
              <div
                v-for="img in activeVariant.gallery_images"
                :key="img.id"
                class="swiper-slide"
              >
                <div class="swiper-zoom-container">
                  <!-- TODO: Width and height should prob. be a bit more dynamic, added 500x500 to see if we could get rid of some CLS -->
                  <img
                    :src="
                      img.sizes.shop_single ? img.sizes.shop_single : img.url
                    "
                    :alt="`Bild på ${product.name}`"
                    width="500"
                    height="500"
                    class="swiper-lazy"
                    loading="lazy"
                    @error="imgPlaceholder"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </client-only>
        <!-- TODO: Width and height should prob. be a bit more dynamic, added 500x500 to see if we could get rid of some CLS -->
        <img
          v-if="activeVariant.gallery_images.length === 0"
          :src="
            activeVariant.featured_image.sizes !== undefined &&
            activeVariant.featured_image.sizes.shop_single
              ? activeVariant.featured_image.sizes.shop_single
              : activeVariant.featured_image.url
          "
          :alt="activeVariant.name"
          loading="lazy"
          width="500"
          height="500"
          @error="imgPlaceholder"
        />
      </div>
      <div class="product-details">
        <div class="product-details__tags">
          <span v-if="product.meta.eco === 'y'" class="eko" title="Ekologisk" />
          <span
            v-if="product.meta.country_of_origin === 'SE'"
            class="sweden"
            title="Tillverkad i Sverige"
          />
          <span v-if="product.meta.new_item === 'y'" class="new">nyhet</span>
        </div>
        <template v-if="product.brands.length > 0">
          <router-link
            :to="{ name: 'Brand', params: { brand: product.brands[0].name } }"
            class="product-details__brand-link"
          >
            <img
              v-if="product.brands[0].thumbnail !== null"
              class="product-details__brand"
              width="90"
              :src="product.brands[0].thumbnail.url"
              :alt="product.brands[0].name"
            />
            <p v-else class="product-details__brand">
              {{ product.brands[0].name }}
            </p>
          </router-link>
        </template>
        <h2 class="product-details__title">{{ product.name }}</h2>
        <div
          v-if="activeVariant.short_description"
          class="product-details__descr"
          v-html="activeVariant.short_description"
        ></div>
        <div
          v-else-if="
            !activeVariant.short_description && product.short_description
          "
          class="product-details__descr"
          v-html="product.short_description"
        ></div>
        <p v-else class="product-details__descr">Beskrivning saknas</p>
        <ul v-if="product.meta.table_of_contents" class="links-specifics">
          <li>
            <a href="#Innehallsforteckning">
              Innehållsförteckning
              <Icon name="arrowRight" type="far" />
            </a>
          </li>
        </ul>
        <div v-if="!product.meta.table_of_contents" class="links-placeholder" />
        <div class="product-details__alternatives">
          <form>
            <div
              v-if="!product.variations"
              class="container"
              :style="{
                borderColor: amount[product.id] > 0 ? '#004999' : '',
                opacity: !product.stock.in_stock ? '0.5' : '1'
              }"
            >
              <div class="wrap">
                <img
                  v-if="product.featured_image.url"
                  width="60"
                  :src="
                    product.featured_image.sizes &&
                    product.featured_image.sizes.medium
                      ? product.featured_image.sizes.medium
                      : product.featured_image.url
                  "
                  :alt="product.name"
                  loading="lazy"
                  @error="imgPlaceholder"
                />
                <div class="wrap-details">
                  <span class="name">{{ product.name }}</span>
                  <span v-if="product.stock.in_stock" class="stock-info"
                    >I lager</span
                  >
                  <span v-else class="stock-info">Ej i lager</span>
                </div>
              </div>
              <div class="add-rm">
                <button
                  aria-label="Minska antal"
                  :disabled="!product.stock.in_stock"
                  :style="{
                    cursor: !product.stock.in_stock ? 'not-allowed' : 'pointer'
                  }"
                  @click.prevent="
                    amount[product.id] > 0
                      ? amount[product.id]--
                      : amount[product.id]
                  "
                >
                  <Icon name="minus" type="far" />
                </button>
                <span class="amount">{{ amount[product.id] }}</span>
                <button
                  aria-label="Öka antal"
                  :disabled="!product.stock.in_stock"
                  :style="{
                    cursor: !product.stock.in_stock ? 'not-allowed' : 'pointer'
                  }"
                  @click.prevent="amount[product.id]++"
                >
                  <Icon name="plus" type="far" />
                </button>
              </div>
              <div class="wrap-price">
                <span
                  class="price"
                  :style="{
                    color: product.price.on_sale ? '#BA2C34' : 'black'
                  }"
                >
                  {{
                    new Intl.NumberFormat('sv-SE', {
                      maximumFractionDigits: 0
                    }).format(product.price.price)
                  }}:-
                </span>
                <span v-if="product.price.on_sale" class="originalPrice">
                  {{
                    new Intl.NumberFormat('sv-SE', {
                      maximumFractionDigits: 0
                    }).format(product.price.regular_price)
                  }}:-
                </span>
              </div>
            </div>
            <div
              v-for="(alternative, index) in product.variations || []"
              :key="index"
              class="container"
              :style="{
                borderColor:
                  activeVariantId === alternative.id ? '#004999' : '',
                opacity: !alternative.stock.in_stock ? '0.5' : '1'
              }"
              @click="activeVariantId = alternative.id"
            >
              <div class="wrap">
                <img
                  v-if="alternative.featured_image.url"
                  width="60"
                  :src="
                    alternative.featured_image.sizes &&
                    alternative.featured_image.sizes.medium
                      ? alternative.featured_image.sizes.medium
                      : alternative.featured_image.url
                  "
                  :alt="alternative.name"
                  loading="lazy"
                />
                <div class="wrap-details">
                  <span
                    v-if="
                      hasAttrib(alternative, 'pa_size') &&
                      hasAttrib(alternative, 'pa_color')
                    "
                    class="name"
                  >
                    {{ alternative.attributes.attribute_pa_size }},
                    {{ alternative.attributes.attribute_pa_color }}
                  </span>
                  <span
                    v-else-if="hasAttrib(alternative, 'pa_size')"
                    class="name"
                  >
                    {{ alternative.attributes.attribute_pa_size }}
                  </span>
                  <span
                    v-else-if="hasAttrib(alternative, 'pa_color')"
                    class="name"
                  >
                    {{ alternative.attributes.attribute_pa_color }}
                  </span>
                  <span v-if="alternative.stock.in_stock" class="stock-info"
                    >I lager</span
                  >
                  <span v-else class="stock-info">Ej i lager</span>
                </div>
              </div>
              <client-only>
                <div class="add-rm">
                  <button
                    aria-label="Minska antal"
                    :disabled="!alternative.stock.in_stock"
                    :style="{
                      cursor: !alternative.stock.in_stock
                        ? 'not-allowed'
                        : 'pointer'
                    }"
                    @click.prevent="
                      amount[alternative.id] > 0
                        ? amount[alternative.id]--
                        : amount[alternative.id]
                    "
                  >
                    <Icon name="minus" type="far" />
                  </button>
                  <span class="amount">{{ amount[alternative.id] }}</span>
                  <button
                    aria-label="Öka antal"
                    :disabled="!alternative.stock.in_stock"
                    :style="{
                      cursor: !alternative.stock.in_stock
                        ? 'not-allowed'
                        : 'pointer'
                    }"
                    @click.prevent="amount[alternative.id]++"
                  >
                    <Icon name="plus" type="far" />
                  </button>
                </div>
              </client-only>
              <div class="wrap-price">
                <span
                  class="price"
                  :style="{
                    color: alternative.price.on_sale ? '#BA2C34' : 'black'
                  }"
                >
                  {{
                    new Intl.NumberFormat('sv-SE', {
                      maximumFractionDigits: 0
                    }).format(alternative.price.price)
                  }}:-
                </span>
                <span v-if="alternative.price.on_sale" class="originalPrice">
                  {{
                    new Intl.NumberFormat('sv-SE', {
                      maximumFractionDigits: 0
                    }).format(alternative.price.regular_price)
                  }}:-
                </span>
              </div>
            </div>
            <button
              class="add-to-cart"
              type="submit"
              :disabled="totalAmount <= 0 || addingToCartState !== 'idle'"
              :style="{
                opacity: totalAmount <= 0 ? '0.7' : '1',
                cursor: totalAmount <= 0 ? 'not-allowed' : 'pointer'
              }"
              @click.prevent="addToCartNow"
            >
              <Icon
                v-if="addingToCartState === 'loading'"
                name="spinnerLight"
              />
              <Icon v-else name="shoppingBag" type="far" />
              <span>
                {{ addToCartText }}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
    <SingleProductSpecifics :product="activeVariant" />
    <div v-if="listRelatedProducts" class="grid-related">
      <ProductCard v-for="p in listRelatedProducts" :key="p.id" :product="p" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.product-gallery {
  :deep(.swiper-lazy-preloader) {
    border: 4px solid $main;
  }

  :deep(.swiper-pagination) {
    .swiper-pagination-bullet {
      background-color: $border;
      height: 12px;
      width: 12px;
      opacity: 0.5;
    }
    .swiper-pagination-bullet-active {
      background-color: $main;
      opacity: 1;
    }
  }
}
.wrapper {
  display: flex;
}
.product {
  display: grid;
  grid-template-columns: repeat(1, 100vw);
  width: 100%;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 50%);
    padding: 6rem 0;
  }
  &-gallery {
    margin: 0 2rem;

    .swiper-container {
      @media (min-width: 1025px) {
        &:hover {
          .zoom-text {
            opacity: 1;
          }
        }
      }
    }

    .zoom-text {
      font-family: $inter;
      font-weight: 400;
      font-size: 0.65em;
      text-align: center;
      margin-right: 0.5em;
      transition: 0.4s ease-in-out;
      @media (min-width: 1025px) {
        opacity: 0;
      }

      svg {
        font-size: 130%;
        color: rgb(172, 172, 172);
      }
    }

    .swiper-wrapper {
      margin-bottom: 3em;
    }

    img {
      object-fit: contain;
      width: 100%;
      max-height: 500px;
    }
  }
  &-details {
    padding: 0 1em;

    &__brand-link {
      display: inline-block;
      color: $main;
    }
    &__brand {
      margin-top: 1em;
      letter-spacing: -0.2px;
      font-family: $gothic;
      font-weight: 700;
      font-size: 0.875rem;
    }
    &__title {
      font-family: $brandon;
      font-weight: 700;
      font-size: 2.5rem;
      letter-spacing: -0.54px;
      text-transform: uppercase;
      margin-top: 0.2em;
      @media (max-width: 1024px) {
        font-size: 2em;
      }
    }
    &__tags {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.3em;
      }
      /* TAGS */
      .sweden {
        order: 1;
        position: relative;
        background: $main;
        height: 22px;
        width: 22px;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 9px;
          width: 22px;
          height: 4px;
          background: #ffd500;
        }
        &::after {
          content: '';
          position: absolute;
          width: 4px;
          height: 22px;
          left: 6px;
          top: 0;
          background: #ffd500;
        }
      }
      .eko {
        order: 2;
        background-color: #62aa40;
        height: 22px;
        width: 22px;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        /* Style for "eko" */
        color: $white;
        font-family: $brandon;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.12px;
        text-align: center;
        line-height: 1.5;
      }
      .new {
        order: 3;
        padding: 0.1em 0.3em;
        color: $white;
        font-family: $brandon;
        font-weight: 900;
        font-size: 0.8em;
        text-transform: uppercase;
        background: black;
      }
      /* END OF TAGS */
    }
    &__descr {
      max-width: 700px;
      font-family: $inter;
      font-weight: 400;
      font-size: 1.25rem;
      letter-spacing: -0.27px;
      line-height: 35px;
      @media (max-width: 1024px) {
        font-size: 1.1rem;
      }

      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 105px; // line-height * 3 (fallback for line-clamp)
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .links-specifics {
      margin: 1em 0 1.5em;
      padding: 0;
      list-style-type: none;
      li {
        margin: 0.4em 0;

        a {
          color: $main;
          font-size: 0.8125rem;
          font-family: $inter;
          font-weight: 400;
          position: relative;
          //hover underline effect
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -5px;
            left: 0;
            background-color: $main;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            &::before {
              visibility: visible;
              transform: scaleX(1);
            }
          }
          svg {
            font-size: 0.78em;
          }
        }
      }
    }
    .links-placeholder {
      height: 60px;
    }
    &__alternatives {
      @media (max-width: 425px) {
        margin-left: -1.1em;
        margin-right: -1.1em;
      }
      .container {
        cursor: pointer;
      }
    }
    form {
      display: flex;
      flex-flow: column wrap;
      .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid $highlight;
        border-radius: 2px;
        background: $white;
        padding: 0.5em 1em;
        margin: 0.5em 0;
        @media (max-width: 425px) {
          margin: 0.2em 0 0;
        }
        @media (min-width: 1024px) {
          max-width: 450px;
        }
        .wrap {
          display: flex;
          img {
            display: block;
            margin: 0 0.5rem 0 0;
            height: 60px;
            width: 60px;
            object-fit: contain;
          }
          &-details {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            margin-right: 0.5em;
            .name {
              font-size: 0.9375rem;
              font-family: $inter;
              font-weight: 600;
              line-height: 1.5;
              max-width: 170px;
            }
            .stock-info {
              font-size: 0.75rem;
              font-family: $inter;
              font-weight: 300;
              letter-spacing: -0.13px;
            }
          }
        }
        .add-rm {
          width: 95px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #e9e9e9;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.5em;
          margin-right: 0.5em;
          button {
            background: transparent;
            border: none;
            font-weight: bold;
            display: flex;
            padding: 0.4em 0.2em;
            svg {
              width: 10px;
              height: 10px;
            }
          }
          .amount {
            font-family: $inter;
            font-weight: 300;
            font-size: 0.8125rem;
            letter-spacing: -0.17px;
          }
        }
        .wrap-price {
          display: flex;
          flex-flow: column wrap;
          .price {
            font-family: $brandon;
            font-weight: 900;
            font-size: 1.25rem;
            letter-spacing: -0.27px;
            line-height: 35px;
          }
          .originalPrice {
            font-family: $brandon;
            font-weight: 900;
            font-size: 0.625rem;
            letter-spacing: -0.13px;
            line-height: 19px;
            text-align: right;
            text-decoration: line-through;
            margin-top: -0.5em;
            position: relative;
            right: 0.5em;
          }
        }
      }

      .add-to-cart {
        color: $white;
        background: $main;
        border-radius: 2px;
        border: none;
        padding: 1.4em;
        margin-top: 2rem;
        transition: 0.2s ease;
        &:hover {
          background: lighten($color: $main, $amount: 7);
        }
        @media (max-width: 425px) {
          margin: 0 0 2em;
        }
        @media (min-width: 1024px) {
          max-width: 365px;
        }
        span {
          font-family: $inter;
          font-weight: 600;
          font-size: 0.9375rem;
          letter-spacing: -0.2px;
        }
        svg {
          margin-right: 0.25rem;
        }
      }
    }
  }
  .icon.icon-spinnerLight {
    animation: spinner 2s linear infinite forwards;
  }
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.grid-related {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border-top: 1px solid $highlight;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  //   .ad-section {
  //     grid-column: span 2;
  //     grid-row: span 4;
  //     display: grid;
  //     grid-template-columns: repeat(2, 1fr);
  //     @media (min-width: 1024px) {
  //       grid-column: span 2;
  //       grid-row: span 1;
  //       grid-auto-flow: dense;
  //     }
  //     article {
  //       position: relative;
  //       display: flex;
  //       flex-flow: column wrap;
  //       justify-content: center;
  //       overflow: hidden;
  //       padding: 4em 5em 4em 3em;
  //       border-right: 1px solid $highlight;
  //       border-bottom: 1px solid $highlight;
  //       h2 {
  //         font-family: $brandon;
  //         font-weight: 400;
  //         font-size: 38px;
  //         letter-spacing: -1.85px;
  //         text-transform: uppercase;
  //         margin-bottom: 0.3em;
  //       }
  //       h3 {
  //         font-family: $brandon;
  //         font-size: 2.375em;
  //         font-weight: 300;
  //         letter-spacing: -1.85px;
  //         text-transform: uppercase;
  //         margin: 0;
  //       }
  //       p {
  //         font-family: $inter;
  //         font-size: 0.938em;
  //         font-weight: 300;
  //         letter-spacing: -0.73px;
  //       }
  //     }
  //     & > *:first-child {
  //       grid-column: span 2;
  //     }
  //   }
}
</style>
