<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'SingleProductSpecifics',
  components: {
    Icon
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    imgPlaceholder(e: any) {
      e.target.src = require('../assets/djur&natur.svg')
    }
  }
})
</script>

<template>
  <div
    v-if="product.brands.length > 0 || product.description || product.meta.table_of_contents"
    class="specifics"
  >
    <div class="wrap">
      <div class="specifics-descr">
        <h2 v-if="product.description">Produktbeskrivning</h2>
        <div v-if="product.description" class="descr" v-html="product.description" />
        <router-link
          v-if="product.brands.length > 0"
          :to="{ name: 'Brand', params: { brand: product.brands[0].name } }"
          class="more-brand"
        >
          <div class="more-brand-imgs">
            <img
              v-if="product.featured_image.url"
              height="200"
              :src="
                product.featured_image.sizes && product.featured_image.sizes.medium
                  ? product.featured_image.sizes.medium
                  : product.featured_image.url
              "
              :alt="product.name"
              loading="lazy"
              @error="imgPlaceholder"
            />
            <div class="wrapper">
              <img
                v-if="product.brands[0].thumbnail !== null"
                width="90"
                :src="product.brands[0].thumbnail.url"
                :alt="product.brands[0].name"
              />
              <p v-else>
                {{ product.brands[0].name }}
              </p>
            </div>
          </div>
          <span class="more-brand-text">
            Se fler produkter från {{ product.brands[0].name }}
            <Icon name="arrowRight" type="far" />
          </span>
        </router-link>
      </div>
      <div class="specifics-contents">
        <template v-if="product.meta.table_of_contents">
          <div id="Innehallsforteckning" class="specifics-contents-item">
            <h3>Innehållsförteckning</h3>
            <p>
              {{ product.meta.table_of_contents }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.specifics {
  display: flex;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    border-top: 1px solid $highlight;
  }
  .wrap {
    margin: 6rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    font-family: $inter;
    font-family: 400;
    font-size: 0.875rem;
    @media (max-width: 425px) {
      margin: 2rem 2rem 6rem;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .specifics-descr {
      @media (max-width: 375px) {
        padding: 0 2rem;
      }
      @media (min-width: 376px) and (max-width: 425px) {
        padding: 0 1rem;
      }
      h2 {
        font-size: 1.3125rem;
      }
      .descr,
      p {
        line-height: 2;
        max-width: 430px;
      }

      .descr {
        /* Style elements coming from the v-html here */
        :deep(ul) {
          margin: 0;
          padding: 0 1.3em 0;
          li {
            margin-bottom: 1.5em;
          }
        }
      }

      .more-brand {
        display: flex;
        align-items: center;
        margin-bottom: 6rem;
        color: $black;
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
        &:hover {
          .more-brand-text::before {
            visibility: visible;
            transform: scaleX(1);
          }
        }

        &-imgs {
          position: relative;
          margin-right: 1em;
          img {
            width: 200px;
            object-fit: contain;
          }
          .wrapper {
            bottom: 0;
            left: 0;
            position: absolute;
            background: $white;
            box-shadow: 0 0 30px lightgrey;
            padding: 0.5em 1em;
            img {
              width: 90px;
              object-fit: contain;
            }
          }
        }
        &-text {
          color: $main;
          position: relative;
          //hover underline effect
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -5px;
            left: 0;
            background-color: $main;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out;
          }
          svg {
            font-size: 0.78em;
          }
        }
      }
    }
    .specifics-contents {
      display: flex;
      width: 100%;
      flex-flow: column wrap;
      .specifics-contents-item {
        display: flex;
        border-top: 1px solid $highlight;
        border-left: 1px solid $highlight;
        border-right: 1px solid $highlight;
        &:last-of-type {
          border-bottom: 1px solid $highlight;
        }
        &::before {
          content: '';
          display: block;
          height: 500px; /* fixed header height*/
          margin: -500px 0 0; /* negative fixed header height */
        }
      }
      h3 {
        width: 100%;
        min-width: 159px;
        padding: 0.8em 0.5em;
        margin: 0;
        font-size: 1em;
        border-right: 1px solid $highlight;
      }
      p {
        padding: 0.8em 4.3em 0.8em 0.8em;
        margin: 0;
        line-height: 1.5;
        max-width: 350px;
        overflow-wrap: break-word;
      }
    }
  }
}
</style>
